import React, { ReactElement, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Separator from "../../components/Separator";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { isIOS, responsive, makeArrayUnique } from "../../util/utils";
import { ImageItem } from "../CreatePost";
import Carousel from "react-multi-carousel";
import { AsyncImage } from "loadable-image";
import { useSelector } from "react-redux";
import { T } from "react-translator-component";
import appServices from "../../services/app-services/appServices";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import { TagsInput } from "react-tag-input-component";
import uploadFiles from "../../util/upload-multiple-file";
import { useLocation } from "react-router-dom";
import LoadingModal from "../../components/LoadingModal";
import { styles as oldstyles } from "../styles";
interface FormSection {
  inputs: { [key: string]: string };
  free: boolean;
  group: boolean;
}

function CreateNewEvent(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const groupInfo = useSelector((state: any) => state.gcGroup);

  const { event } = location.state || {};
  console.log("event", event);

  const { user } = useSelector((state: any) => state.userAuth);
  const [loading, setLoading] = React.useState(false);

  const [selectedValue, setSelectedValue] = useState("No");
  const [selectedValueThird, setSelectedValueThird] =
    useState("キャンセルはできるが返金不可");
  const [votingQuestion, setVotingQuestion] = useState("");
  const [votingOption, setVotingOption] = useState("No");
  const [commentsOption, setCommentsOption] = useState(0);
  const [checked, setChecked] = useState(false);
  const [inputFields, setInputFields] = useState([
    { placeholder: "犬派", value: "" },
    { placeholder: "猫派", value: "" },
    { placeholder: "両方好き", value: "" },
  ]);
  const [title, setTitle] = useState("");
  const [eventStartDate, setEventStartDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [eventEndDate, setEventEndDate] = React.useState<Dayjs | null>(
    eventStartDate
  );
  const [eventOverview, setEventOverview] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [applicationStartDate, setApplicationStartDate] =
    React.useState<Dayjs | null>(dayjs(new Date()));
  const [applicationEndDate, setApplicationEndDate] =
    React.useState<Dayjs | null>(dayjs(new Date()));
  const [votingEndDate, setVotingEndDate] = React.useState<Dayjs | null>(
    dayjs(new Date())
  );
  const [venueName, setVenueName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState("");

  const [tags, setTags] = useState<any[]>([]);
  const [images, setImages] = useState<ImageItem[]>([]);
  const [removedImages, setRemovedImage] = useState<ImageItem[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<ImageItem[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<ImageItem[]>([]);
  const [editTags, setEditTags] = useState<any[]>([]);
  const [removeTags, setRemoveTags] = useState<any[]>([]);
  const [finalEditTags, setFinalEditTags] = useState<any>([]);

  const [formSections, setFormSections] = useState<FormSection[]>([
    // {
    //   inputs: { name: "", numberOfParticipants: "", FeeAmount: "" },
    //   free: false,
    //   group: false,
    // },
  ]);

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    category: "",
    group: "",
    tags: "",
    images: "",
    acceptVotes: "",
    acceptComments: "",
    agree: "",
  });

  // Handler to add a new section
  const handleAddSection = () => {
    setFormSections([
      ...formSections,
      {
        inputs: { name: "", numberOfParticipants: "", FeeAmount: "" },
        free: false,
        group: false,
      },
    ]);
  };

  // Handler for input changes
  const handleInputChangeEvent = (index: number, event: any) => {
    const { name, value } = event.target;
    const updatedSections = [...formSections];
    updatedSections[index].inputs[name] = value;
    setFormSections(updatedSections);
  };

  // Handler for radio button changes
  const handleRadioChangeEvent = (
    index: number,
    radioGroup: "free" | "group",
    value: any
  ) => {
    const updatedSections = [...formSections];
    updatedSections[index][radioGroup] = value;
    setFormSections(updatedSections);
  };

  const handleAddField = () => {
    setInputFields([...inputFields, { value: "", placeholder: "" }]);
  };

  const handleInputChange = (index: number, event: any) => {
    const values = [...inputFields];
    values[index].value = event.target.value;
    setInputFields(values);
  };

  const handleRemoveField = (index: number) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };
  const handleImageUploadAndroid = () => {
    try {
      window.parent.postMessage("image_upload", "*");
    } catch (error) {
      console.error("Image upload failed", error);
    }
  };

  const handleImageUploadIOS = (event: any) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const totalFiles = images.length + selectedFiles.length;

      if (totalFiles > 5) {
        // setError("画像は5枚まで許可されます");
        // Only take the required number of files to stay within the limit
        selectedFiles.splice(5 - images.length);
      }

      const newImages = selectedFiles.map((file: any) => {
        let reader = new FileReader();
        return new Promise((resolve) => {
          reader.onload = (e: any) => {
            resolve({
              name: file.name,
              file: file,
              imageSrc: e.target.result,
              path: undefined,
              _destroy: "0",
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImages).then((loadedImages) => {
        setImages((prevImages: any) => [...prevImages, ...loadedImages]);
        // setError(null); // Clear any previous error
      });
    }
  };
  const removeImage = (index: number) => {
    setImages((prev: ImageItem[]) =>
      prev.filter((_: ImageItem, i: number) => i !== index)
    );
  };
  const handleDeleteTag = (tag: string) => {
    if (event) {
      try {
        const removedTg = event?.post_tags?.filter(
          (item: any) => item.tag === tag
        );
        const removed = [
          ...removeTags,
          { tag: tag, _destroy: "1", id: removedTg[0].id },
        ];

        setRemoveTags(removed);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const validateForm2 = () => {
    let valid = true;
    let newErrors = {
      title: "",
      description: "",
      category: "",
      group: "",
      tags: "",
      images: "",
      acceptVotes: "",
      acceptComments: "",
      agree: "",
    };
    if (title === "") {
      newErrors.title = "タイトルを入力してください";
      valid = false;
    }
    // if (description == "") {
    //   newErrors.description = "内容を入力してください";
    //   valid = false;
    // }
    // if (selectedCategory == undefined) {
    //   newErrors.category = "カテゴリーを選択してください";
    //   valid = false;
    // }
    // if (selectedGroup == undefined) {
    //   newErrors.group = "グループを選択してください";
    //   valid = false;
    // }
    // if (tagArray.length == 0) {
    //   newErrors.tags = "タグを入力してください";
    //   valid = false;
    // }
    // if (acceptVotes == "") {
    //   newErrors.acceptVotes = "投票を選択してください";
    //   valid = false;
    // }
    // if (
    //   acceptVotes == "Yes" &&
    //   voteOptions.some((option) => !option.value || option.value.trim() === "")
    // ) {
    //   newErrors.acceptVotes = "投票内容を入力してください";
    //   valid = false;
    // }

    setErrors(newErrors);
    return valid;
  };

  const submit = async () => {
    if (!validateForm2()) {
      return;
    } else {
      setLoading(true);
      try {
        const allImages = filesToUpload
          ? await uploadFiles.uploadMultipleImages(filesToUpload)
          : [];

        const votingOptions = inputFields.map((option) => {
          return { option_text: option.value };
        });

        const eventsArray = formSections
          .map((section) => {
            const { name, FeeAmount, numberOfParticipants } = section.inputs;
            if (!name || name === "") return undefined;
            return {
              name: name,
              number_of_participants: numberOfParticipants,
              fee_amount: FeeAmount,
              free: section.free,
              group: section.group,
            };
          })
          .filter((event) => event !== undefined);

        const body = {
          post: {
            title: title,
            details: "",
            user_id: user?.id,
            member_group_id: groupInfo?.id,
            group_id: 1,
            category_id: 1,
            moderation_count: 0,
            expiry_date: votingEndDate?.format("YYYY-M-D"),
            is_anonymous: 1,
            enable_comments: commentsOption,
            enable_participants: 1,
            number_of_participants: 2,
            post_events_attributes: [
              {
                title: title,
                description: eventOverview,
                start_date: eventStartDate?.format("YYYY-M-D"),
                end_date: eventEndDate?.format("YYYY-M-D"),
                venue_name: venueName,
                postal_code: postalCode,
                address: address,
                url: websiteURL,
                anyone_can_participate: true,
                application_install_needed: false,
                application_start_date:
                  applicationStartDate?.format("YYYY-M-D"),
                application_end_date: applicationEndDate?.format("YYYY-M-D"),
                application_cancellation_rule: selectedValueThird,
                voting_question: votingQuestion,
                post_event_participant_settings_attributes: eventsArray,
              },
            ],
            vote_options_attributes: votingOptions,
            images_attributes: [
              ...allImages,
              ...removedImages,
              ...filesUploaded,
            ],
            tags_attributes: finalEditTags,
          },
        };

        let res;

        if (event) {
          const response = await gcServices.editEvent(event.id, body);
          res = response?.data?.success;
        } else {
          const response = await appServices.createGroupEvent(body);
          res = response?.success;
        }
        console.log(res);
        setLoading(false);

        if (res === true) {
          // navigate("/groupeventsetting", {
          //   state: {
          //     groupInfo: groupInfo,
          //   },
          // });
          navigate(-1);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log("Edit Post:", event);
    if (event) {
      // eslint-disable-next-lin
      const {
        title,
        enable_comments,
        expiry_date,
        post_tags,
        vote_options,
        images,
      } = event;

      const {
        description,
        start_date,
        end_date,
        venue_name,
        postal_code,
        address,
        url,
        voting_question,
        application_end_date,
        application_start_date,
        application_cancellation_rule,
      } = event?.post_events[0];

      const Applications = event?.post_events[0]?.participant_settings;

      setTitle(title);
      setEventOverview(description);
      setSelectedValueThird(application_cancellation_rule);
      setApplicationEndDate(dayjs(application_end_date));
      setApplicationStartDate(dayjs(application_start_date));
      setVotingEndDate(dayjs(expiry_date));
      setCommentsOption(enable_comments ? 1 : 0);
      setEventStartDate(dayjs(start_date));
      setEventEndDate(dayjs(end_date));
      setVenueName(venue_name);
      setPostalCode(postal_code);
      setAddress(address);
      setWebsiteURL(url);
      setVotingOption(voting_question ? "Yes" : "No");
      setVotingQuestion(voting_question);
      const votes = vote_options.map((vote: any) => ({
        id: vote.id,
        post_id: vote.post_id,
        value: vote.option_text,
      }));

      // eslint-disable-next-line array-callback-return
      Applications.map((application: any) => {
        const { name, number_of_participants, fee_amount, free, group } =
          application;

        const newApplication = {
          inputs: {
            name: name,
            numberOfParticipants: number_of_participants,
            FeeAmount: fee_amount,
          },
          free: free,
          group: group,
        };
        setFormSections((prevSections) => [...prevSections, newApplication]);
      });
      setSelectedValue(Applications.length > 0 ? "Yes" : "No");

      const tags = post_tags.map((tag: any) => tag.tag);
      setTags(tags);
      setEditTags(post_tags);

      const imgs = images.map((img: any) => ({
        path: img.path,
        id: img.id,
        name: img.path,
        file: undefined,
        imageSrc: undefined,
      }));
      console.log("imgs", imgs);

      setImages(imgs);

      if (votes.length > 0) {
        setInputFields(votes);
      }

      // const totalVotes = vote_options.reduce((total: number, vote: any) => total + vote.votes_count, 0);
      // setVoteCounts(totalVotes);
    }
    // eslint-disable-next-line
  }, [event]);

  useEffect(() => {
    const filesToUpload = images.filter((img) => img.file !== undefined);
    setFilesToUpload(filesToUpload);

    const filesUploaded = images.filter((img) => img.path !== undefined);
    setFilesUploaded(filesUploaded);
  }, [images]);

  useEffect(() => {
    const select = tags.map((tag) => {
      return { tag: tag };
    });
    // console.log(select);
    const all = select.concat(editTags);
    // var ages = array.map(function(obj) { return obj.age; });
    const uniqueArray = makeArrayUnique(all);
    // console.log(uniqueArray);

    const f = uniqueArray.concat(removeTags);
    const fin = makeArrayUnique(f);
    console.log("fin", fin);
    setFinalEditTags(fin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags, removeTags]);

  useEffect(() => {
    function handleMessage(event: any) {
      if (event.source === window.parent) {
        // Log the message sent from the parent window

        if (
          event?.data === "" ||
          event?.data === null ||
          event?.data === undefined
        )
          return;
        try {
          const base64Data = event?.data.split(",")[1];
          const byteCharacters = atob(base64Data);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "image/jpeg" });
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const fileName = `any_${timestamp}.png`;

          // Create a File object from the Blob
          const file = new File([blob], fileName, { type: "image/jpeg" });

          setImages((prevImages: any) => [
            ...prevImages,
            {
              name: fileName,
              file: file,
              imageSrc: event?.data,
              path: undefined,
              _destroy: "0",
            },
          ]);
        } catch (error) {
          // alert("error" + error);
        }
      }
    }

    // Add an event listener to listen for messages from the parent window
    window.addEventListener("message", handleMessage);

    // eslint-disable-next-line
  }, []);

  const saveRemoveImg = (img: ImageItem) => {
    if (img.path !== undefined) {
      const item = img;
      const addDestroy = [...removedImages, { ...item, _destroy: "1" }];
      // console.log(addDestroy);
      setRemovedImage(addDestroy);
      // console.log(removedImages);
    }
  };
  const newEventsOptions = () => {
    return (
      <Box
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {formSections.map((section, index) => (
          <Box sx={{ marginTop: "10px", background: "white" }} key={index}>
            <Typography
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "35px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              参加項目名：{index + 1}（必須）
            </Typography>
            <TextField
              required
              sx={{
                marginTop: "15px",
                background: "white",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
              value={section.inputs.name}
              onChange={(e) => handleInputChangeEvent(index, e)}
              name="name"
              placeholder="大人"
              multiline
              rows={1} // Set the number of rows
              variant="outlined" // You can choose "filled" or "standard" as well
              fullWidth // Makes the TextField take the full width of its container
            />
            <Separator sx={{ marginTop: "10px" }} color="#D1D1D1" />
            <Typography
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "35px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              参加人数（必須）
            </Typography>
            <Box sx={{ display: "flex", alignItems: "end" }}>
              <TextField
                required
                sx={{
                  marginTop: "15px",
                  background: "white",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  width: "45%",
                }}
                value={section.inputs.numberOfParticipants}
                onChange={(e) => handleInputChangeEvent(index, e)}
                name="numberOfParticipants"
                disabled={section.group}
                placeholder="100"
                multiline
                rows={1} // Set the number of rows
                variant="outlined" // You can choose "filled" or "standard" as well
              />
              <FormControl component="fieldset">
                <RadioGroup
                  name={`radio1-${index}`}
                  value={section.group}
                  onChange={(event) =>
                    handleRadioChangeEvent(
                      index,
                      "group",
                      event.target.value === "true" ? true : false
                    )
                  }
                  row
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="人"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="組"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Separator sx={{ marginTop: "10px" }} color="#D1D1D1" />
            <Typography
              sx={{
                paddingLeft: "30px",
                paddingRight: "30px",
                paddingTop: "35px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              料金（必須）
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "end",
              }}
            >
              <FormControl
                component="fieldset"
                sx={{
                  paddingLeft: "30px",
                }}
              >
                <RadioGroup
                  name={`radio2-${index}`}
                  value={section.free}
                  onChange={(event) =>
                    handleRadioChangeEvent(
                      index,
                      "free",
                      event.target.value === "true" ? true : false
                    )
                  }
                  row
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio disabled={event} />}
                    label="無料"
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio disabled={event} />}
                    label=""
                  />
                </RadioGroup>
              </FormControl>

              <TextField
                required
                style={{
                  marginTop: "15px",
                  background: "white",
                  marginLeft: "-15px",
                  paddingRight: "5px",
                  width: "25%",
                }}
                value={section.inputs.FeeAmount}
                onChange={(e) => handleInputChangeEvent(index, e)}
                name="FeeAmount"
                disabled={section.free || event}
                placeholder="1000"
                multiline
                rows={1} // Set the number of rows
                variant="outlined" // You can choose "filled" or "standard" as well
              />
              <Typography>円</Typography>
            </Box>
            <Separator sx={{ marginTop: "10px" }} color="#D1D1D1" />
          </Box>
        ))}
      </Box>
    );
  };

  const VotingQuestions = () => {
    return (
      <Box>
        {inputFields.slice(0, 5).map((input, index) => (
          <Box key={index}>
            <Typography
              sx={{
                paddingTop: "20px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              回答0{index + 1}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                sx={{ marginTop: "1px", background: "white" }}
                multiline
                placeholder={input.placeholder}
                value={input.value}
                rows={1} // Set the number of rows
                variant="outlined" // You can choose "filled" or "standard" as well
                fullWidth // Makes the TextField take the full width of its container
                onChange={(event) => handleInputChange(index, event)}
              />
              <DeleteIcon
                onClick={() => handleRemoveField(index)}
                sx={{ color: "red", fontSize: "35px" }}
              />
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <div>
      <div
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <LoadingModal visible={loading} handleClose={() => {}} />

        <GCBackButton title="もどる" onClick={() => navigate(-1)} />

        <Box style={styles.eventPostingBox}>
          <Typography style={{ fontWeight: "700" }}>
            <img
              src={`${groupInfo?.groupImage}`}
              alt={"user"}
              style={{
                ...oldstyles.farmProfileIcon,
                height: 50,
                width: 50,
                margin: 0,
              }}
            />
            &nbsp;{groupInfo?.groupName}
          </Typography>
        </Box>
      </div>
      <Box component="div" style={styles.container}>
        <Typography
          sx={{
            borderBottom: "2px solid #86BB57",
            marginTop: "10px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          画像のアップロード （最大5枚）（任意）
        </Typography>
        <Box>
          <Carousel responsive={responsive}>
            {images.map((item: ImageItem, index: number) => (
              <div key={item.name} style={styles.carouselImage}>
                <AsyncImage
                  style={styles.carouselImage}
                  src={
                    item.path
                      ? `${process.env.REACT_APP_AWS_PATH_BUCKET}${
                          event ? "/images" : ""
                        }${item.path}`
                      : item.imageSrc
                  }
                  alt=""
                />
                <DeleteIcon
                  style={{ top: -30, position: "relative", color: "red" }}
                  onClick={() => {
                    removeImage(index);
                    saveRemoveImg(item);
                  }}
                  color={"inherit"}
                />
              </div>
            ))}
          </Carousel>
          {isIOS(user?.client_id) ? (
            <Button
              disabled={images.length >= 5}
              color={"secondary"}
              sx={styles.uploadImageButton}
              component="label"
              variant="outlined"
            >
              {T("upload_images_txt")}
              <input
                accept="image/*"
                type="file"
                hidden
                multiple
                onChange={handleImageUploadIOS}
              />
            </Button>
          ) : (
            <Button
              disabled={images.length >= 5}
              color={"secondary"}
              sx={styles.uploadImageButton}
              component="label"
              variant="outlined"
              onClick={handleImageUploadAndroid}
            >
              {T("upload_images_txt")}
            </Button>
          )}
        </Box>

        <Typography sx={styles.typographyText}>タイトル（必須）</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => {
            if (event.target.value.length > 30) return;
            setTitle(event.target.value);
            setErrors({ ...errors, title: "" });
          }}
          value={title}
          multiline
          rows={2} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
          error={!!errors.title}
          helperText={errors.title}
        />

        <Typography sx={styles.typographyText}>イベント開催日・期間</Typography>

        <Typography
          sx={{
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          開始日（必須）
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              value={eventStartDate}
              onChange={(newDate) => setEventStartDate(newDate)}
            />
          </DemoContainer>
        </LocalizationProvider>

        <Typography
          sx={{
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          終了日（開催日と異なる場合は記入）
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker", "DatePicker"]}>
            <DatePicker
              value={eventEndDate}
              onChange={(newValue) => setEventEndDate(newValue)}
            />
          </DemoContainer>
        </LocalizationProvider>

        <Typography sx={styles.typographyText}>イベント会場</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => setVenueName(event.target.value)}
          value={venueName}
          placeholder="会場名"
          multiline
          rows={1} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />
        <TextField
          style={styles.textField}
          onChange={(event) => setPostalCode(event.target.value)}
          value={postalCode}
          placeholder="郵便番号"
          multiline
          rows={1} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />
        <TextField
          style={styles.textField}
          onChange={(event) => setAddress(event.target.value)}
          value={address}
          placeholder="住所"
          multiline
          rows={1} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />

        <Typography sx={styles.typographyText}>イベント概要</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => setEventOverview(event.target.value)}
          value={eventOverview}
          multiline
          rows={10} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />

        <Typography sx={styles.typographyText}>WEBサイトのURL</Typography>
        <TextField
          style={styles.textField}
          onChange={(event) => setWebsiteURL(event.target.value)}
          value={websiteURL}
          placeholder="[公式サイト] https://www.abc.com [イベント会場] https://www.event.com"
          multiline
          rows={5} // Set the number of rows
          variant="outlined" // You can choose "filled" or "standard" as well
          fullWidth // Makes the TextField take the full width of its container
        />

        <Typography sx={styles.typographyText}>
          応募ボタンを設置しますか？
        </Typography>
        <FormControl component="fieldset">
          <FormLabel component="legend"></FormLabel>
          <RadioGroup
            value={selectedValue}
            onChange={(event) => setSelectedValue(event.target.value)}
          >
            <FormControlLabel
              value="No"
              control={<Radio />}
              label="設置しない。 "
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            />
            <ul>
              <li>誰でも何人でも参加できるイベントです。</li>
            </ul>

            <FormControlLabel
              value="Yes"
              control={<Radio />}
              label="設置する：先着順"
              sx={{ "& .MuiFormControlLabel-label": { fontWeight: "bold" } }}
            />
            <ul>
              <li>参加人数の制限があるイベントです。</li>
              <li>選考は先着順です。 </li>
              <li>参加料がある場合は参加申し込み時にお支払いとなります。</li>
              <li>参加人数に達成後、ボタンは押せなくなります。</li>
            </ul>
            {selectedValue === "Yes" && (
              <>
                <Box style={styles.containerInternal}>
                  <Typography sx={styles.conditions}>
                    【応募開始日（必須）】
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        value={applicationStartDate}
                        onChange={(newValue) =>
                          setApplicationStartDate(newValue)
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                  <Typography sx={styles.conditions}>
                    【応募終了日（必須）】
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker", "DatePicker"]}>
                      <DatePicker
                        value={applicationEndDate}
                        onChange={(newValue) => setApplicationEndDate(newValue)}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
                {newEventsOptions()}

                <Button
                  disabled={false}
                  color="secondary"
                  sx={styles.submitButton}
                  style={{ marginBottom: "20px" }}
                  size={"large"}
                  variant="outlined"
                  onClick={handleAddSection}
                >
                  参加項目を追加する
                </Button>
                <Box
                  style={styles.containerInternal}
                  sx={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <Typography sx={styles.conditions}>
                    【当選後のキャンセルについて】
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      value={selectedValueThird}
                      onChange={(event) => {
                        setSelectedValueThird(event.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="キャンセルはできるが返金不可"
                        control={<Radio />}
                        label="キャンセルはできるが返金不可"
                      />
                      <FormControlLabel
                        value="1週間前まではキャンセルも返金も可能"
                        control={<Radio />}
                        label="1週間前まではキャンセルも返金も可能"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </>
            )}
          </RadioGroup>
        </FormControl>

        <Typography sx={styles.typographyText}>タグ</Typography>

        <TagsInput
          value={tags}
          onChange={setTags}
          onRemoved={handleDeleteTag}
          name="fruits"
          separators={[" ", ",", "Enter", "return"]}
        />
        <Typography sx={styles.typographyText}>
          投票を設定しますか？（必須）
        </Typography>

        <FormControl component="fieldset">
          <RadioGroup
            value={votingOption}
            onChange={(event) => setVotingOption(event.target.value)}
            row
          >
            <FormControlLabel value="Yes" control={<Radio />} label="はい" />
            <FormControlLabel value="No" control={<Radio />} label="いいえ" />
          </RadioGroup>
        </FormControl>
        {votingOption === "Yes" && (
          <Box>
            <Typography sx={styles.typographyText}>投票したい質問</Typography>
            <TextField
              style={styles.textField}
              onChange={(event) => setVotingQuestion(event.target.value)}
              value={votingQuestion}
              placeholder="犬と猫どちらが好きですか？"
              multiline
              rows={2} // Set the number of rows
              variant="outlined" // You can choose "filled" or "standard" as well
              fullWidth // Makes the TextField take the full width of its container
            />
            {VotingQuestions()}
            <Button
              disabled={false}
              color="secondary"
              sx={styles.submitButton}
              size={"large"}
              variant="outlined"
              onClick={handleAddField}
            >
              回答を追加する
            </Button>
            <Typography
              style={{
                paddingTop: "30px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              投票終了日 （最大３ヶ月先まで）
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker", "DatePicker"]}>
                <DatePicker
                  sx={{ background: "white" }}
                  value={votingEndDate}
                  onChange={(newValue) => setVotingEndDate(newValue)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        )}
        <Typography sx={styles.typographyText}>
          コメントを受け付けますか？（任意）
        </Typography>

        <FormControl component="fieldset">
          <RadioGroup
            value={commentsOption}
            onChange={(event) =>
              setCommentsOption(parseInt(event.target.value))
            }
            row
          >
            <FormControlLabel value={1} control={<Radio />} label="はい" />
            <FormControlLabel value={0} control={<Radio />} label="いいえ" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box component="div" style={styles.container2}>
        <Typography
          style={{
            borderBottom: "2px solid #86BB57",
            paddingTop: "30px",
            textAlign: "left",
            fontSize: "15px",
          }}
        >
          注意事項（必須）
        </Typography>
        <Typography sx={styles.conditions}>
          ターゲットを絞った誹謗中傷の内容ではありません。
        </Typography>
        <Separator sx={{ marginTop: "10px" }} color="gray" />
        <Typography sx={styles.conditions}>
          わいせつ行為や犯罪を促進する内容及び募集ではありません。
        </Typography>
        <Separator sx={{ marginTop: "10px" }} color="gray" />
        <Typography sx={styles.conditions}>
          投稿内容に責任を持ちます。
        </Typography>
        <Separator sx={{ marginTop: "10px" }} color="gray" />

        <FormControlLabel
          style={{ paddingTop: "30px" }}
          control={
            <Checkbox
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              name="singleCheckbox"
            />
          }
          label="上記の注意事項を確認し、すべての事項に同意いたします。"
        />

        <Button
          disabled={!checked}
          color="secondary"
          onClick={submit}
          sx={styles.submitButton}
          size={"large"}
          variant="outlined"
          endIcon={
            <ArrowForwardIosIcon
              sx={{ position: "absolute", right: 0, top: 0, margin: "10px" }}
            />
          }
        >
          投稿する
        </Button>
      </Box>
    </div>
  );
}
const styles = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },
  eventPostingBox: {
    display: "flex",
    gap: "15px",
    alignItmes: "center",
    paddingTop: "10px",
    paddingLeft: "10px",
  },
  container: {
    background: "#ECEFF1",
    padding: "20px 30px 20px 30px",
  },
  container2: {
    padding: "20px 30px 20px 30px",
  },
  containerInternal: {
    background: "white",
    padding: "20px 30px 20px 30px",
    borderRadius: "5px",
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
  conditions: {
    paddingTop: "5px",
    textAlign: "left",
    fontSize: "15px",
  },
  typographyText: {
    borderBottom: "2px solid #86BB57",
    paddingTop: "50px",
    textAlign: "left",
    fontSize: "15px",
  },
  carouselImage: {
    height: 100,
    width: 180,
    borderRadius: 2,
  },
  uploadImageButton: {
    alignSelf: "center",
    margin: "10px auto",
    maxWidth: 200,
    display: "flex",
    borderRadius: 20,
    fontFamily: "mplus-1c-bold",
  },
  textField: {
    marginTop: "15px",
    background: "white",
  },
};
export default CreateNewEvent;
