import React, { ReactElement, useEffect } from "react";
import { styles } from "../styles";
import GroupIcon from "@mui/icons-material/Group";
import CurrencyYenSharpIcon from "@mui/icons-material/CurrencyYenSharp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import ButtonArrow from "../../components/GreenCity/ButtonArrow";
import { useLocation, useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { setGroupInfo } from "../../redux/Reducers/gcGroupReducer";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import { Badge } from "@mui/material";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
// interface Props {}

function GroupOwnerSettingPage(): ReactElement {
  const [cancelRequests, setCancelRequests] = React.useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { groupInfo } = location.state || {}; // Destructure your props

  console.log("groupInfo in GroupOwnerSettingPage", groupInfo);

  const getCancelRequests = async () => {
    try {
      const res = await gcServices.getCancelRequestsOfGroup(groupInfo?.id);
      setCancelRequests(res?.post_event_users);
    } catch (error) {
      console.error(error);
    }
  };

  const getGroupInfo = () => {
    return {
      id: groupInfo.id,
      groupName: groupInfo.name,
      groupIsPublic: groupInfo?.group_type,
      groupImage: groupInfo?.group_icon
        ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`
        : "",
      groupMainImage: groupInfo?.banner_image
        ? `${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.banner_image}`
        : "", // From state
      groupDesc: groupInfo?.description, // From state
      groupAnnouncements: groupInfo?.member_group_activities, // From state
      groupWebURLs: groupInfo?.social_links, // You can replace this with the actual value
      groupApprovalRequired: true,
      piiRequired: groupInfo?.personal_information_needed ? "YES" : "NO",
      groupIsLimited: true,
      groupMaxMembers: groupInfo.participants_count,
      groupMemberShipFeeAmount: groupInfo?.fee_amount,
      groupPaymentAutomatic: groupInfo?.payment_type,
      groupAutomaticPaymentDay: groupInfo?.membership_fee_deduction_date,
      isMonthly: groupInfo?.fee_type === "yearly", // Static value
      groupPaymentField: groupInfo?.fee_amount,
      groupPaymentDescription: groupInfo?.fee_explanation,
      user: groupInfo.user,
      owner: groupInfo.owner,
    };
  };

  const handleReview = () => {
    // Dispatch action to save group details
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/groupinforeview");
  };

  const handleMembers = () => {
    navigate("/members", {
      state: {
        groupInfo: groupInfo,
      },
    });
  };

  const handleMemberList = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/memberlist", {
      state: {
        groupInfo: groupInfo,
      },
    });
  };

  const navigateToViewGroup = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/viewGroupPost");
  };

  const navigateToGroupCalendar = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/groupeventscalendar");
  };

  const navigateToCancelRequests = () => {
    // dispatch(setGroupInfo(getGroupInfo()));
    navigate("/cancelrequests", {
      state: {
        groupId: groupInfo?.id,
      },
    });
  };

  const navigateToGroupEventSetting = () => {
    dispatch(setGroupInfo(getGroupInfo()));
    navigate("/groupeventsetting");
  };

  useEffect(() => {
    getCancelRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ paddingTop: 100, marginLeft: 16 }}>
      <GCBackButton title="グループトップ" onClick={() => navigate(-1)} />
      <Box style={{ padding: 16 }}>
        <Typography style={{ fontWeight: "700" }}>
          <img
            src={`${process.env.REACT_APP_AWS_PATH_BUCKET}/images${groupInfo?.group_icon}`}
            alt={"user_img"}
            style={{
              ...styles.farmProfileIcon,
              height: 50,
              width: 50,
              margin: 0,
            }}
          />
          &nbsp;{groupInfo?.name}
        </Typography>
        <Typography style={{ fontSize: 10, textAlign: "center" }}>
          あなたはこのグループの「リーダー」です。
        </Typography>
      </Box>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <SupervisedUserCircleOutlinedIcon /> グループページ
        </p>
        {/* // */}

        <ButtonArrow
          title="グループトップ"
          onClick={() => {
            handleReview();
          }}
        />

        <ButtonArrow
          title="グループへの招待リンク"
          onClick={() => {
            handleMembers();
          }}
        />
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <BorderColorOutlinedIcon /> 投稿
        </p>
        {/* // */}

        <ButtonArrow title="みんなの投稿" onClick={navigateToViewGroup} />

        <ButtonArrow
          title="イベント投稿"
          onClick={navigateToGroupEventSetting}
        />

        <ButtonArrow title="グループ内の投稿" onClick={() => {}} />

        <ButtonArrow title="グループ内のプライベート投稿" onClick={() => {}} />
      </div>
      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <GroupIcon /> 投稿
        </p>

        <ButtonArrow
          title="メンバー一覧"
          onClick={() => {
            handleMemberList();
          }}
        />

        <ButtonArrow title="メンバーを招待する" onClick={() => {}} />
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <CurrencyYenSharpIcon
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              width: 26,
              height: 26,
              borderRadius: 13,
              padding: 2,
              margin: 2,
            }}
          />
          投稿
        </p>
        <Typography style={{ fontSize: 10, textAlign: "center" }}>
          グループの「めぶくPay」残高：0 円
        </Typography>

        <ButtonArrow title="メンバー一覧" onClick={() => {}} />

        <ButtonArrow title="メンバーを招待する" onClick={() => {}} />
        <Box>
          <Box sx={{ position: "absolute", right: "76px", marginTop: "-8px" }}>
            <Badge
              badgeContent={cancelRequests?.length} // Notification count
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  minWidth: 20,
                  height: 20,
                  background: "#FF3B00",
                  borderRadius: "50%",
                  fontSize: "0.75rem", // Adjust the text size
                },
              }}
            />
          </Box>
          <ButtonArrow
            title="イベントキャンセル料の精算"
            onClick={navigateToCancelRequests}
          />
        </Box>
      </div>

      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <InsertCommentIcon />
          チャット
        </p>

        <ButtonArrow
          title="チャットリスト"
          onClick={() => {
            navigate("/chatmembers", {
              state: {
                groupInfo: groupInfo,
              },
            });
          }}
        />
      </div>
      <div style={styles.portionBg}>
        <p style={{ fontSize: 14, fontWeight: 600 }}>
          <CalendarMonthIcon
            style={{
              borderWidth: 1,
              width: 26,
              height: 26,
              padding: 2,
              margin: 2,
            }}
          />
          カレンダー
        </p>

        <ButtonArrow
          title="イベントカレンダーをる"
          onClick={navigateToGroupCalendar}
        />
      </div>

      {/* navigate("/commenthistory") */}
    </div>
  );
}

export default GroupOwnerSettingPage;
