import { Avatar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import { Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chat from "../../components/GreenCity/Chat";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import appServices from "../../services/app-services/appServices";
import { useEffect, useState } from "react";
import { Message } from "../../util/types";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AddChatUserList from "../../components/Models/AddChatUserList";
import UserDeleteModal from "../../components/Models/GreenCity/UserDeleteModal";

const UserChat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { chatId, chatUsers, groupMembers, groupInfo, isCreator } = location.state || {};
  const { user } = useSelector((state: any) => state.userAuth);
  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [openOption, setOpenOption] = useState(false);
  const [Options, setOptions] = useState<any[]>([]);
  const [currentChatUsers, setCurrentChatUsers] = useState(chatUsers || []);
  const [currentGroupMember] = useState<any[]>(groupMembers || []);
  const [pendingMessage, setPendingMessage] = useState<string | null>(null);
  const [lastMessageId, setLastMessageId] = useState<number | null>(null);
  const [confirmDeleteUser, setConfirmDeleteUser] = useState<number | null>(null);
  const message = "このユーザーをチャットから削除してもよろしいですか ?";
  const [chatType, setChatType] = useState("");

  // eslint-disable-next-line

  function filterMembers() {
    const membersWithStatus = [];
    for (let i = 0; i < currentGroupMember.length; i++) {
      let member = currentGroupMember[i];
      if (member.user.id != user.id) {
        membersWithStatus.push({
          ...member,
          isInChat: currentChatUsers?.some((chatUser: any) => chatUser.id === member.user.id),
        });
      }
    }
    return membersWithStatus;
  }

  const handleOptionsModal = () => {
    if (!openOption) {
      setOptions(filterMembers());
    }
    setOpenOption(!openOption);
  };

  const handleSelect = async (selectedUser: any) => {
    const updatedChatUsers = [
      ...currentChatUsers,
      {
        name: selectedUser?.name,
        id: selectedUser?.id,
        image: selectedUser?.image,
        can_message: true,
      },
    ];
    setCurrentChatUsers(updatedChatUsers);
  };

  const checkScrollArrows = () => {
    const container = document.getElementById("members-container");
    if (container) {
      setShowLeftArrow(container.scrollLeft > 0);
      setShowRightArrow(container.scrollLeft < container.scrollWidth - container.clientWidth);
    }
  };

  useEffect(() => {
    checkScrollArrows();
    window.addEventListener("resize", checkScrollArrows);
    console.log("group-Info", groupInfo);
    return () => window.removeEventListener("resize", checkScrollArrows);

    // eslint-disable-next-lin
  }, [handleOptionsModal]);

  const handleScroll = (direction: "left" | "right") => {
    const container = document.getElementById("members-container");
    if (container) {
      const scrollAmount = 100;
      const newPosition =
        direction === "left" ? Math.max(0, scrollPosition - scrollAmount) : Math.min(container.scrollWidth - container.clientWidth, scrollPosition + scrollAmount);

      container.scrollTo({
        left: newPosition,
        behavior: "smooth",
      });
      setScrollPosition(newPosition);

      setTimeout(checkScrollArrows, 300);
    }
  };

  const getChatMessages = () => {
    appServices.getChatMessages(chatId).then((res) => {
      setChatType(res?.data?.chat?.chat_type);
      setAllMessages(res.data.chat.messages);
      if (res.data.chat.messages.length > 0) {
        setLastMessageId(res.data.chat.messages[res.data.chat.messages.length - 1].id);
      }
    });
  };

  const getNewMessages = (checkforLastMessageId: boolean = true) => {
    // console.log("getting new messages", checkforLastMessageId, lastMessageId);
    if (checkforLastMessageId && !lastMessageId) return;

    appServices.getLatestMessages(chatId, lastMessageId || 0, user.auth_token).then((res) => {
      // console.log("latest messages", res.data);
      if (res.data.messages.length > 0) {
        setAllMessages((prev) => [...prev, ...res.data.messages]);
        setLastMessageId(res.data.messages[res.data.messages.length - 1].id);
      }
      setIsLoading(false);
      setPendingMessage(null);
    });
  };

  useEffect(() => {
    getChatMessages();
    setOptions(filterMembers());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => getNewMessages(true), 10000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [lastMessageId]);

  const handleSendMessage = (message: string) => {
    setPendingMessage(message);
    setIsLoading(true);
    appServices
      .sendMessage(message, user.auth_token, chatId)
      .then((res) => {
        getNewMessages(false);
      })
      .catch((err) => {
        console.log(err, "error-sendMessage");
        setIsLoading(false);
        setPendingMessage(null);
      });
  };

  const handleDeleteUser = (userId: number) => {
    appServices.deleteUserChat(chatId, userId, user.auth_token).then((res) => {
      setCurrentChatUsers(currentChatUsers.filter((user: any) => user.id !== userId));
      setConfirmDeleteUser(null);
    });
  };

  const showPlusIcon = () => {
    console.log("current chat memebers", Options);
    if (isCreator) {
      return true;
    } else {
      const currentUser = Options?.find((o: any) => o.user.id === user?.id);
      return currentUser?.role !== "member";
    }
  };

  return (
    <Box>
      <Box sx={style.headerBox}>
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box sx={style.groupContainer}>
          <Box sx={style.scrollContainer}>
            {showLeftArrow && <ArrowBackIosIcon sx={style.scrollIcon} onClick={() => handleScroll("left")} />}
            <Box id="members-container" sx={style.membersWrapper} onScroll={checkScrollArrows}>
              <Box sx={style.memberContainer}>
                {currentChatUsers?.map(
                  (member: any) =>
                    member.id !== user.id && (
                      <Box key={member.id} sx={style.eventPostingBox} onClick={() => isCreator && setConfirmDeleteUser(member.id)}>
                        <Avatar src={"data:image/jpeg;base64," + member.image} sx={{ width: 30, height: 30 }} />
                        <Typography sx={style.memberTitle}>{member.name}</Typography>
                      </Box>
                    )
                )}
              </Box>
            </Box>
            {showRightArrow && <ArrowForwardIosIcon sx={style.scrollIcon} onClick={() => handleScroll("right")} />}
          </Box>
          {showPlusIcon() && <AddCircleOutlineIcon sx={style.addIcon} onClick={handleOptionsModal} />}
          <AddChatUserList
            isCreator={isCreator}
            open={openOption}
            onClose={handleOptionsModal}
            options={Options}
            onSelect={handleSelect}
            chatId={chatId}
            chatType={chatType}
            groupInfo={groupInfo}
            authToken={user.auth_token}
            handleDeleteUser={(userId: number) => setConfirmDeleteUser(userId)}
            currentUserId={user.id}
            user={currentChatUsers.map((member: any) => member.user)}
            currentChatUsers={currentChatUsers}
          />
        </Box>
      </Box>
      <Chat currentUser={user} messages={allMessages} handleSendMessage={handleSendMessage} pendingMessage={pendingMessage} />
      <UserDeleteModal
        showButton={true}
        message={message}
        open={confirmDeleteUser !== null}
        onClose={() => setConfirmDeleteUser(null)}
        onContinue={() => {
          handleDeleteUser(confirmDeleteUser!);
        }}
      />
    </Box>
  );
};

export default UserChat;

const style = {
  headerBox: {
    padding: "10px 16px",
    paddingTop: "100px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
  },
  groupContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "10px",
  },
  memberContainer: {
    display: "flex",
    gap: "10px",
    width: "max-content",
    marginTop: "5px",
  },
  eventPostingBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    paddingTop: "3px",
    paddingLeft: "5px",
  },
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px",
    color: "white",
  },
  memberTitle: {
    fontWeight: "700",
    // marginTop: "5px",
  },
  addIcon: {
    color: "#4792E0",
    fontSize: "35px",
    cursor: "pointer",
  },
  scrollContainer: {
    display: "flex",
    alignItems: "center",
    width: "80%",
    gap: "8px",
  },
  membersWrapper: {
    width: "100%",
    overflow: "hidden",
  },
  scrollIcon: {
    color: "#4792E0",
    cursor: "pointer",
    fontSize: "16px",
    flexShrink: 0,
    "&:hover": {
      opacity: 0.7,
    },
  },
};
