import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import GCBackButton from "../GreenCity/GCBackButton";
import { useSelector } from "react-redux";
import TableComponent from "../GreenCity/TableComponent";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import gcServices from "../../services/app-services/greenCity/gcServicesR6";
import dayjs from "dayjs";
const UserEventPaymentDetails = () => {
  const { user } = useSelector((state: any) => state.userAuth);

  const [detail, setDetail] = useState<any>([]);

  const navigate = useNavigate();
  const location = useLocation();

  const { role, userPaymentDetail } = location.state || {};

  const [paymentDetails] = useState(
    JSON.parse(userPaymentDetail?.application_details)
  );
  console.log(paymentDetails);

  const getEventDetails = async () => {
    try {
      const res = await gcServices.getSingleEvent(userPaymentDetail?.event_id);
      setDetail(res?.post_event);
    } catch (error) {
      console.error(error);
    }
  };

  const cancel = async () => {
    try {
      paymentDetails.forEach((detail: any) => {
        detail.cancelledApplicationDate = dayjs(new Date()).format("YYYY-M-D");
      });

      let body = {};
      if (role === "member") {
        if (paymentDetails?.length > 0) {
          body = {
            id: userPaymentDetail?.id,
            status: "Cancel-pending",
            application_details: JSON.stringify(paymentDetails),
          };
        } else {
          body = {
            id: userPaymentDetail?.id,
            status: "Cancelled",
          };
        }
      } else if (role === "leader") {
        body = {
          id: userPaymentDetail?.id,
          status: "Cancelled",
        };
      }
      await gcServices.cancelEventApplication(body);
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getEventDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box>
      <div
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingRight: 16,
          paddingBottom: "12px",
        }}
      >
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
        <Box component="div" style={styles.topButtonContainer}>
          <Box sx={{ display: "flex" }}>
            <CalendarMonthIcon
              style={{
                borderWidth: 1,
                width: 26,
                height: 26,
                padding: 2,
                margin: 2,
              }}
            />
            <Typography style={{ marginTop: "5px" }}>
              参加イベント詳細
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <TableComponent
            data={[
              {
                col1: "主催者",
                col2: detail?.address,
              },
              {
                col1: "イベント名",
                col2: detail?.title,
              },
              {
                col1: "開催日",
                col2: detail?.start_date,
              },
            ]}
          />
        </Box>
        <Box sx={{ marginTop: 4 }}>
          <Typography
            sx={{
              borderBottom: "2px solid #86BB57",
              marginTop: "10px",
              textAlign: "left",
              fontSize: "15px",
            }}
          >
            【参加項目】
          </Typography>
          {paymentDetails?.map((item: any, index: number) => (
            <Box key={index} sx={{ paddingTop: "10px", paddingBottom: "10px" }}>
              <TableComponent
                data={[
                  {
                    col1: "項目",
                    col2: item?.name,
                  },
                  {
                    col1: "参加料",
                    col2: `1名: ${Number(item?.fee_amount)}円`,
                  },
                  {
                    col1: "申し込み人数",
                    col2: item?.number_of_participants,
                  },
                  {
                    col1: "合計料金",
                    col2: item?.total_amount,
                  },
                ]}
              />
            </Box>
          ))}
          <Box sx={{ paddingTop: "10px" }}>
            <TableComponent
              data={[
                {
                  col1: "お支払い金額",
                  col2: paymentDetails?.reduce(
                    (sum: number, item: any) => sum + item?.total_amount,
                    0
                  ),
                },
              ]}
            />
            <Typography
              sx={{
                borderBottom: "2px solid #86BB57",
                paddingTop: "20px",
                marginBottom: "10px",
                textAlign: "left",
                fontSize: "15px",
              }}
            >
              【お申し込み者】
            </Typography>
            <TableComponent
              data={[
                {
                  col1: "ニックネーム",
                  col2: user?.nickname,
                },
              ]}
            />
            {role === "member" && (
              <>
                <Typography
                  sx={{
                    borderBottom: "2px solid #86BB57",
                    paddingTop: "20px",
                    marginBottom: "10px",
                    textAlign: "left",
                    fontSize: "15px",
                  }}
                >
                  【人数を変更する】
                </Typography>
                <Typography>
                  大変申し訳ございませんが、人数を変更することはでき
                  ません。一度キャンセルし、再度お申し込みください。
                </Typography>
                <Typography
                  sx={{
                    borderBottom: "2px solid #86BB57",
                    paddingTop: "20px",
                    marginBottom: "10px",
                    textAlign: "left",
                    fontSize: "15px",
                  }}
                >
                  【キャンセルについて】
                </Typography>
                <Typography>下記ボタンからキャンセルを行えます。</Typography>
                <Typography>
                  キャンセル後はいかなる場合でもキャンセルの取り消し
                  は行えません。改めてお申し込みください。
                </Typography>
                <Typography
                  sx={{
                    borderBottom: "2px solid #86BB57",
                    paddingTop: "20px",
                    marginBottom: "10px",
                    textAlign: "left",
                    fontSize: "15px",
                  }}
                >
                  【キャンセル料について】
                </Typography>
                <Typography>イベントの1週間前までは返金が可能です。</Typography>
              </>
            )}
          </Box>
          {userPaymentDetail?.status === "Cancel-pending" ? (
            <Button
              color="secondary"
              sx={styles.submitButton}
              size={"large"}
              disabled={
                role === "member" &&
                userPaymentDetail?.status === "Cancel-pending"
              }
              onClick={cancel}
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "10px",
                  }}
                />
              }
            >
              {role !== "member" ? "Approve Request" : "Cancel Pending"}
            </Button>
          ) : (
            <Button
              color="secondary"
              sx={styles.submitButton}
              onClick={cancel}
              size={"large"}
              variant="outlined"
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    margin: "10px",
                  }}
                />
              }
            >
              キャンセルする
            </Button>
          )}
        </Box>
      </div>
    </Box>
  );
};

const styles = {
  eventPostingIcon: {
    background: "#FF3B00",
    borderRadius: "50%",
    padding: "3px 6px 3px 6px",
    color: "white",
  },

  topButtonContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    marginTop: 10,
  },

  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
};

export default UserEventPaymentDetails;
