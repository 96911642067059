export const isIOS = (client_id: string) => {
  return client_id === "CL002" || client_id === "CL016";
};

export const isAndroid = (client_id: string) => {
  return client_id === "CL001" || client_id === "CL015";
};

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

export function makeArrayUnique(inputArray: any) {
  const uniqueMap = new Map();
  // Iterate through the array and use the "tag" property as the key in the map
  inputArray.forEach((item: any) => {
    if (item.tag) {
      uniqueMap.set(item.tag.toLowerCase(), item);
    }
  });
  // Convert the values of the map back to an array
  const uniqueArray = Array.from(uniqueMap.values());
  return uniqueArray;
}
