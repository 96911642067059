import { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import EventApplicationDetails from "../../components/Phase2/EventApplicationDetails";
import GCBackButton from "../../components/GreenCity/GCBackButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { colors } from "../../common/colors";
import Separator from "../../components/Separator";
import PaymentConfirmationModal from "../../components/Models/PaymentConfirmationModal";
import { useSelector } from "react-redux";
import appServices from "../../services/app-services/appServices";

const EventApplicationPayment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.userAuth);
  const { detail, selectedValues } = location.state || {};
  const [checked, setChecked] = useState(false);

  const [isPaymentConfirmationModalOpen] = useState(false);

  const [finalValues, setFinalValues] = useState(
    selectedValues?.map((value: any) => value)
  ); // Initialize with default values

  // Handle change for dropdowns
  const handleChange = (index: number) => (event: SelectChangeEvent) => {
    const newValues = [...selectedValues];
    newValues[index] = parseInt(event.target.value, 10);
    setFinalValues(newValues);
  };

  // Calculate the sum of all selected values
  const totalSum = finalValues.reduce((acc: any, value: any) => acc + value, 0);

  const createPayload = () => {
    const eventDetail = detail.participant_settings.map(
      (item: any, index: any) => {
        return {
          id: item.id,
          name: item.name,
          free: item.free,
          group: item.group,
          fee_amount: item.fee_amount,
          number_of_participants: finalValues[index],
          total_amount: item.fee_amount * finalValues[index],
        };
      }
    );
    return eventDetail;
  };

  const AddEventActivity = async () => {
    try {

      const eventPayload = createPayload();

      const body = {
        post_event_id: detail?.id,
        user_id: user?.id,
        status: "applied",
        application_details: JSON.stringify(eventPayload),
      };
      await appServices.addEventActivity(body);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotalAmount = () => {
    let Amount: number = 0;
    detail.participant_settings.map((item: any, index: any) => {
      return (Amount += item.fee_amount * finalValues[index]);
    });

    return Amount;
  };

  return (
    <Box>
      <PaymentConfirmationModal open={isPaymentConfirmationModalOpen} />
      <Box
        style={{
          paddingTop: 100,
          paddingLeft: 16,
          paddingBottom: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
        }}
      >
        <GCBackButton title="もどる" onClick={() => navigate(-1)} />
      </Box>
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <EventApplicationDetails
          detail={detail}
          selectedValues={finalValues}
          handleChange={handleChange}
        />
      </Box>
      <Box sx={{ padding: "20px 20px" }}>
        <Box sx={style.finalAmountBox}>
          <Box sx={{ display: "flex" }}>
            <Typography>合計人数 : </Typography>
            <Typography sx={{ fontWeight: 600, paddingLeft: "5px" }}>
              {" "}
              {totalSum}人{" "}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography>合計料金 : </Typography>
            <Typography
              sx={{ fontWeight: 600, color: "red", paddingLeft: "5px" }}
            >
              {" "}
              {calculateTotalAmount()}円
            </Typography>
          </Box>
        </Box>

        <Separator sx={{ marginTop: "10px" }} color={colors.farm_gray100} />

        <Typography sx={{ color: colors.farm_gray200, paddingTop: "10px" }}>
          ※1つのめぶくゆで最大5名様分まで応募できます。
          ※お支払い方法は「めぶくPay」です。めぶくPayのご
          登録を済ませてからお申し込みください。
          ※お申し込み前に「めぶくPay」に残高があるかご確認 ください。
        </Typography>
        <Separator sx={{ marginTop: "10px" }} color={colors.farm_gray100} />

        <FormControlLabel
          style={{ paddingTop: "30px" }}
          control={
            <Checkbox
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              name="singleCheckbox"
            />
          }
          label="この内容で問違いありません。"
        />
        <Button
          disabled={!checked}
          color="secondary"
          sx={style.submitButton}
          size={"large"}
          variant="outlined"
          // onClick={() => setIsPaymentConfirmationModalOpen(true)}
          onClick={AddEventActivity}
          endIcon={
            <ArrowForwardIosIcon
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                margin: "10px",
              }}
            />
          }
        >
          申し込む
        </Button>
      </Box>
    </Box>
  );
};

const style = {
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
  finalAmountBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    paddingTop: "5px",
    paddingBottom: "10px",
    paddingLeft: "10px",
  },
};

export default EventApplicationPayment;
