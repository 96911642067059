import React, { useEffect, useState } from "react";
import { colors } from "../../common/colors";
import { Box, Button, SelectChangeEvent, Typography } from "@mui/material";
import Separator from "../Separator";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EventApplicationDetails from "./EventApplicationDetails";
import { useNavigate } from "react-router-dom";
import appServices from "../../services/app-services/appServices";
import { useSelector } from "react-redux";

interface Props {
  detail: any;
}

const EventDetailSeeMore = ({ detail }: Props) => {
  const navigate = useNavigate();
  const { user } = useSelector((state: any) => state.userAuth);
  const groupInfo = useSelector((state: any) => state.gcGroup);

  const [isExpanded, setIsExpanded] = useState(false);
  const [userPaymentDetail, setUserPaymentDetail] = useState<any>({});

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  // State to keep track of selected values
  const [selectedValues, setSelectedValues] = useState(
    detail?.participant_settings?.map(() => 0)
  ); // Initialize with default values

  // Handle change for dropdowns
  const handleChange = (index: number) => (event: SelectChangeEvent) => {
    const newValues = [...selectedValues];
    newValues[index] = parseInt(event.target.value, 10);
    setSelectedValues(newValues);
  };

  const AddEventActivity = async () => {
    try {
      const body = {
        post_event_id: detail?.id,
        user_id: user?.id,
        status: "applied",
      };
      await appServices.addEventActivity(body);
      navigate(-1);
    } catch (error) {
      console.log(error);
    }
  };

  const getPaymentDetails = async () => {
    try {
      const res = await appServices.getEventPaymentDetails(
        detail?.id,
        user?.id
      );
      console.log(res);

      setUserPaymentDetail(res[0]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPaymentDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTextWithLinks = (text: string) => {
    const urlRegex =
      /\b(?:https?:\/\/)?(?:www\.[^\s]+|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})[\S]*\b/g;

    const replacedText = text?.replace(urlRegex, (url) => {
      if (!/^(?:\d+\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?$/i.test(url)) {
        if (!/^https?:\/\//i.test(url)) {
          url = "https://" + url;
        }
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else {
        return url;
      }
    });

    return replacedText?.replace(/\n/g, "<br>");
  };

  const getTruncatedDescription = (text: string, limit: number) => {
    let truncated = text?.slice(0, limit);
    let lastOpen = truncated?.lastIndexOf("<a ");
    let lastClose = truncated?.lastIndexOf("</a>");

    if (lastOpen > lastClose) {
      truncated = truncated?.slice(0, lastOpen) + "...";
    } else if (truncated?.length < text?.length) {
      truncated += "...";
    }

    return renderTextWithLinks(truncated);
  };

  const navigateToPaymentDetails = () => {
    navigate("/usereventpaymentdetailscreen", {
      state: {
        role: "member",
        userPaymentDetail: userPaymentDetail,
      },
    });
  };

  const renderDescription = () => {
    const truncatedDescription = getTruncatedDescription(
      detail?.description,
      80
    );
    const descriptionHtml = isExpanded
      ? renderTextWithLinks(detail?.description)
      : truncatedDescription;

    return (
      <div
        style={{
          fontSize: 14,
          marginLeft: 6,
          marginRight: 6,
          lineBreak: "anywhere",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: descriptionHtml,
          }}
          onClick={(event) => {
            const target = event.target as HTMLElement;
            if (target.tagName === "A") {
              event.preventDefault();
              const url = (target as HTMLAnchorElement).href;
              console.log("Link clicked:", url);
              // You can add additional logic here, such as opening the URL in a new tab/window
              //window.open(url, "_system");
              try {
                const messageData = {
                  url: url,
                  condition: true,
                };
                window.parent.postMessage(
                  { type: "url", data: messageData },
                  "*"
                );
              } catch (error) {
                console.error(error);
              }
            }
          }}
        />
        {isExpanded && (
          <Box sx={{ marginTop: 2, padding: "0 6px" }}>
            <Box>
              <Typography sx={{ textAlign: "left" }}>WebURL</Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
              <Link to={""}>{detail?.url}</Link>
            </Box>

            <Box sx={{ marginTop: 4 }}>
              <Typography sx={{ textAlign: "left" }}>【募集期間】</Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
              <Typography sx={{ marginTop: 1 }}>
                {detail?.application_start_date} -{" "}
                {detail?.application_end_date}
              </Typography>
            </Box>

            <Box sx={{ marginTop: 4 }}>
              <Typography sx={{ textAlign: "left" }}>
                【その他の情報】
              </Typography>
              <Separator color={colors.farm_green500} sx={{ height: "2px" }} />
              <Typography sx={{ marginTop: 1 }}>
                １つのめぶくIDで5人まで参加のお申し込みがか可能です。
              </Typography>
              <Separator color={colors.farm_gray15} sx={{ height: "2px" }} />
              <Typography sx={{ marginTop: 2 }}>
                参加申し込みにはグループメンバーになる必要があります。
              </Typography>
              <Separator color={colors.farm_gray15} sx={{ height: "2px" }} />
              <Typography sx={{ marginTop: 2 }}>
                お支払い方法は「めぶくPay」です。
              </Typography>
              <Separator color={colors.farm_gray15} sx={{ height: "2px" }} />
            </Box>
            {user?.id === groupInfo?.owner?.id ? (
              <Button
                color="secondary"
                sx={style.submitButton}
                size={"large"}
                variant="outlined"
                // onClick={AddEventActivity}
                endIcon={
                  <ArrowForwardIosIcon
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      margin: "10px",
                    }}
                  />
                }
              >
                Cancel Event
              </Button>
            ) : (
              <Box>
                {userPaymentDetail?.status === "applied" ||
                userPaymentDetail?.status === "Cancel-pending" ? (
                  <Button
                    color="secondary"
                    sx={style.submitButton}
                    size={"large"}
                    variant="outlined"
                    onClick={navigateToPaymentDetails}
                    endIcon={
                      <ArrowForwardIosIcon
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          margin: "10px",
                        }}
                      />
                    }
                  >
                    View Details
                  </Button>
                ) : (
                  detail?.participant_settings?.length === 0 && (
                    <Button
                      color="secondary"
                      sx={style.submitButton}
                      size={"large"}
                      variant="outlined"
                      onClick={AddEventActivity}
                      endIcon={
                        <ArrowForwardIosIcon
                          sx={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                            margin: "10px",
                          }}
                        />
                      }
                    >
                      申し込む
                    </Button>
                  )
                )}
                <Separator color={colors.farm_gray15} sx={{ height: "2px" }} />
                {userPaymentDetail?.status !== "applied" &&
                  userPaymentDetail?.status !== "Cancel-pending" &&
                  detail?.participant_settings?.length > 0 && (
                    <Box>
                      <EventApplicationDetails
                        detail={detail}
                        selectedValues={selectedValues}
                        handleChange={handleChange}
                      />
                      <Button
                        color="secondary"
                        sx={style.submitButton}
                        size={"large"}
                        variant="outlined"
                        onClick={() =>
                          navigate("/eventapplicationpayment", {
                            state: {
                              detail: detail,
                              selectedValues: selectedValues,
                            },
                          })
                        }
                        endIcon={
                          <ArrowForwardIosIcon
                            sx={{
                              position: "absolute",
                              right: 0,
                              top: 0,
                              margin: "10px",
                            }}
                          />
                        }
                      >
                        申し込み内容を確認する
                      </Button>
                    </Box>
                  )}
              </Box>
            )}
          </Box>
        )}
        {/* {detail?.description.length > 80 && ( */}
        <span
          style={{
            fontSize: 12,
            color: colors.farm_gray200,
            cursor: "pointer",
            display: "block", // Make sure the text stays on a new line if desired
            marginTop: 8, // Some space between the text and the "See more/less"
          }}
          onClick={toggleDescription}
        >
          {isExpanded ? "See less" : "See more"}
        </span>
        {/* )} */}
      </div>
    );
  };

  return <div className="post">{renderDescription()}</div>;
};
const style = {
  optionButton: {
    border: "1px solid #4792E0",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "3px",
  },
  submitButton: {
    width: "80%",
    alignSelf: "center",
    margin: "0 auto",
    marginTop: "20px",
    display: "flex",
    borderRadius: 20,
    border: "2px solid",
  },
};

export default EventDetailSeeMore;
