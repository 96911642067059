import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Tab, Tabs } from "react-tabs-scrollable";
import { styles } from "../pages/styles";
import "../App.css";
import { useSelector } from "react-redux";
import { colors } from "../common/colors";
import { Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import BackHandIcon from "@mui/icons-material/BackHandOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColorOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircleOutlined";
export default function Header() {
  const location = useLocation();
  const [activeTab, setActiveTab] = React.useState(0);
  // const [rejectPosts, setRejectPosts] = useState([]);
  const { user } = useSelector((state: any) => state.userAuth);
  useEffect(() => {
    if (user?.id) {
      // setAuthToken(key);
      // setTimeout(async () => {
      //   const rejectPosts = await appServices.getRejectedPosts(user.id);
      //   setRejectPosts(rejectPosts.posts);
      // }, 2000);
    }
  }, [user, location]);

  const onTabClick = (e: any, index: number) => {
    setActiveTab(index);
  };
  useEffect(() => {
    if (location.pathname === "/home") {
      setActiveTab(0);
    } else if (location.pathname === "/facilitator") {
      setActiveTab(1);
    } else if (location.pathname === "/categories") {
      setActiveTab(2);
    } else if (location.pathname === "/myfarm") {
      setActiveTab(3);
    }
    // else if (location.pathname === "/suggestion") {
    //   setActiveTab(4);
    // } else if (location.pathname === "/dashboard") {
    //   setActiveTab(5);
    // } else if (location.pathname === "/myfarm") {
    //   setActiveTab(6);
    // }
  }, [location.pathname]);
  return (
    <div>
      {location.pathname === "/home" ||
      location.pathname === "/categories" ||
      location.pathname === "/facilitator" ||
      location.pathname === "/suggestion" ||
      location.pathname === "/dashboard" ||
      location.pathname === "/myfarm" ||
      location.pathname === "/votingseemore" ||
      location.pathname === "/detailsview" ||
      location.pathname === "/posthistory" ||
      location.pathname === "/search" ||
      location.pathname === "/everyoneshistory" ||
      location.pathname === "/commenthistory" ||
      location.pathname === "/creategroupstep1" ||
      location.pathname === "/creategroupsteptwo" ||
      location.pathname === "/groupinforeview" ||
      location.pathname === "/disclosure" ||
      location.pathname === "/steppii" ||
      location.pathname === "/groupleader" ||
      location.pathname === "/group_search" ||
      location.pathname === "/viewGroupPost" ||
      location.pathname === "/createGroupPost" ||
      location.pathname === "/groupeventsetting" ||
      location.pathname === "/createnewevent" ||
      location.pathname === "/memberlist" ||
      location.pathname === "/members" ||
      location.pathname === "/eventdetail" ||
      location.pathname === "/chatlist" ||
      location.pathname === "/userchat" ||
      location.pathname === "/chatmembers" ||
      location.pathname === "/calenderevent" ||
      location.pathname === "/chat" ||
      location.pathname === "/grouppayment" ||
      location.pathname === "/eventapplicationpayment" ||
      location.pathname === "/groupeventscalendar" ||
      location.pathname === "/usereventpaymentdetailscreen" ||
      location.pathname === "/groupchat" ||
      location.pathname === "/grouproles" ||
      location.pathname === "/cancelrequests" ||
      location.pathname === "/post" ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            height: 76,
            backgroundColor: "white",
            zIndex: 1,
            width: "100%",
          }}
        >
          <Tabs
            hideNavBtns
            hideNavBtnsOnMobile={true}
            tabsContainerStyle={styles.tabContainer}
            activeTab={activeTab}
            onTabClick={onTabClick}
          >
            <Tab
              style={
                activeTab === 0
                  ? { ...styles.activeTab, borderLeftWidth: 0, minWidth: 50 }
                  : { ...styles.unActiveTab, borderLeftWidth: 0, minWidth: 50 }
              }
              key={"1"}
            >
              <div
                style={{
                  height: 6,
                  marginBottom: 4,
                  backgroundColor:
                    activeTab === 0 ? colors.farm_green500 : "#FFFFFF",
                }}
              ></div>
              <Link id="GFG" to="/home">
                <HomeIcon
                  style={{
                    color: activeTab === 0 ? colors.farm_green500 : "#000000",
                  }}
                />
                {/* {activeTab === 0 ? (
               
                ) : (
                 <HomeIcon/>
                )} */}
                <Typography style={styles.tabTitle}>ホーム</Typography>
              </Link>
            </Tab>

            <Tab
              style={
                activeTab === 1
                  ? { ...styles.activeTab, minWidth: 94 }
                  : { ...styles.unActiveTab, minWidth: 94 }
              }
              key={"3"}
            >
              <div
                style={{
                  height: 6,
                  marginBottom: 4,
                  backgroundColor:
                    activeTab === 1 ? colors.farm_green500 : "#FFFFFF",
                }}
              ></div>
              <Link id="GFG" to="/facilitator">
                <BackHandIcon
                  style={{
                    color: activeTab === 1 ? colors.farm_green500 : "#000000",
                  }}
                />

                <Typography style={styles.tabTitle}>意見ひろば</Typography>
              </Link>
            </Tab>
            <Tab
              style={
                activeTab === 2
                  ? { ...styles.activeTab, minWidth: 110 }
                  : { ...styles.unActiveTab, minWidth: 110 }
              }
              key={"2"}
            >
              <div
                style={{
                  height: 6,
                  marginBottom: 4,
                  backgroundColor:
                    activeTab === 2 ? colors.farm_green500 : "#FFFFFF",
                }}
              ></div>
              <Link id="GFG" to="/categories">
                <BorderColorIcon
                  style={{
                    color: activeTab === 2 ? colors.farm_green500 : "#000000",
                  }}
                />

                <Typography style={styles.tabTitle}>みんなの投稿</Typography>
              </Link>
            </Tab>

            <Tab
              style={
                activeTab === 3
                  ? { ...styles.activeTab, minWidth: 65 }
                  : { ...styles.unActiveTab, minWidth: 65 }
              }
              key={"4"}
            >
              <div
                style={{
                  height: 6,
                  marginBottom: 4,
                  backgroundColor:
                    activeTab === 3 ? colors.farm_green500 : "#FFFFFF",
                }}
              ></div>
              <Link id="GFG" to="/myfarm">
                <AccountCircleIcon
                  style={{
                    color: activeTab === 3 ? colors.farm_green500 : "#000000",
                  }}
                />

                <Typography style={styles.tabTitle}>マイページ</Typography>
              </Link>
            </Tab>
            {/* <Tab
            style={activeTab === 5 ? styles.activeTab : styles.unActiveTab}
            key={"6"}
          >
            <Link id="GFG" to="/dashboard">
              {T("Dashboard")}
            </Link>
          </Tab>
          <Tab
            style={activeTab === 6 ? styles.activeTab : styles.unActiveTab}
            key={"7"}
          >
            <Link id="GFG" to="/myfarm">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {T("MyCommunity")}
                {rejectPosts.length === 0 ? null : (
                  <Badge
                    color="error"
                    style={{
                      marginLeft: 13,
                      alignSelf: "center",
                    }}
                    badgeContent={rejectPosts.length}
                  ></Badge>
                )}
              </div>
            </Link>
          </Tab> */}
          </Tabs>
          {/* <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <Link
              style={{
                fontSize: 14,
                color: activeTab === 6 ? colors.farm_green500 : "black",
              }}
              id="GFG"
              to="/myfarm"
            >
              {T("MyCommunity")} {" > "} &nbsp;
            </Link>
            <Link
              style={{
                fontSize: 14,
                color: activeTab === 5 ? colors.farm_green500 : "black",
              }}
              id="GFG"
              to="/dashboard"
            >
              {T("Dashboard")} {" > "}&nbsp;
            </Link>
          </div> */}
        </div>
      ) : null}
    </div>
  );
}
